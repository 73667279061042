<template>
    <ExpandPanelSimple :title="title" barClass="critical white--text" v-if="items.length > 0">
        <v-card color="error white--text" class="elevation-24 my-2" >
            <v-card-text>
                <v-flex xs12 class="mb-1">
                    <v-progress-linear progress color="white" :value="timerProgress" />
                </v-flex>
                <v-data-table :headers="headers" :items="items" class="elevation-2" hide-default-footer :items-per-page.sync="page">
                    <template v-slot:[`item.id`]="{ item }">
                        <v-btn v-if="item.id" class="success" x-small dark :href="href(`/board/${item.name}`)" target="_blank"> Ver alarmes </v-btn>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
    </ExpandPanelSimple>
</template>

<script>
import ExpandPanelSimple from "@/components/core/ExpandPanelSimple.vue";

export default {

    components: { ExpandPanelSimple },

    data() {
        return {
            page: -1,
            items: [],
            timer: null,
            timerProgress: 10, // 3 seconds, after 30 seconds
            headers: [
                { text: "Identificador", value: "name", align: "right", sortable: false },
                { text: "-", value: "id", align: "center", sortable: false },
            ],
        };
    },
    
    computed: {
        title() {
            return `${this.items.length} Gateway(s) com alarme.`;
        }
    },

    created() {
        // this.update();
        this.timer = setInterval(this.timerTick, 300);
    },

    methods: {
        href(url) {
            return `${window.location.origin}${url}`;
        },
        timerTick() {
            if (this.timerProgress > 0) {
                this.timerProgress--;
            } else {
                this.update();
                this.timerProgress = 100;
            }
        },
        async update() {
            await this.$http
                .get(`api/v3/board/error`, { showLoading: false })
                .then((result) => {
                    this.items = result;
                }).catch(() => {
                    this.$eventHub.$emit("msgError", "Erro ao buscar gateways.");
                });
        },
    },

    beforeDestroy() {
        clearInterval(this.timer);
    },
}
</script>
<style scoped>
.v-card {
    display: flex !important;
    flex-direction: column;
}

.v-card__text {
    flex-grow: 1;
    overflow: auto;
}
</style>
